import { useNavigation } from 'react-router';
import type { MouseEvent } from 'react';

export const NavigationBlocker = () => {
  const navigation = useNavigation();

  const handleClick = ( e : MouseEvent<HTMLDivElement> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  if ( navigation.state === 'loading' || navigation.state === 'submitting' ) {
    return (
      <div
        className={ 'fixed inset-0 z-[1000] cursor-wait' }
        onClick={ handleClick }/>
    )
  }

  return null;
}
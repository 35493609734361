import { ApiError } from '@mechhive/api';
import { ImageContextProvider } from '@mechhive/react';
import { internalServerError } from '@mechhive/remix';
import { captureException, setUser } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { redirectDocument, type LinksFunction } from '@vercel/remix';
import * as acceptLanguageParser from 'accept-language-parser';
import isbot from 'isbot';
import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-ultimate';
import type { ShouldRevalidateFunction } from 'react-router';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  data,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useRouteError,
} from 'react-router';
import { z } from 'zod';
import { zx } from 'zodix';
import stylesheet from '~/tailwind.css?url';
import * as gtag from '~/utils/gtags.client';
import type { Route } from './+types/root';
import { createRewarbleApi } from './api/rewarble.api.server';
import { Footer } from './components/Footer';
import { HamburgerMenu } from './components/HamburgerMenu';
import { Header } from './components/Header';
import { NavigationBlocker } from './components/NavigationBlocker';
import { Page } from './components/Page';
import { UserRequest } from './components/user/UserRequest';
import { livechatCookie } from './cookies/livechat.cookie.server';
import { useLanguage } from './hooks/useLanguage';
import { useLanguageServer } from './hooks/useLanguage.server';
import { useRegionServer } from './hooks/useRegion.server';
import { FALLBACK_LANGUAGE, getUrlPathWithoutLanguage } from './locale';
import { commitSession, getSession } from './session.server';
import { countries, nationalities, translations } from './translations.server';
import { FrontApp } from './utils/frontapp.server';
import { getRequestTimeZone } from './utils/timezone.server';

const loaderParamsSchema = z.object( {
  lang: z.string().optional()
} )

export const loader = async ( { request, params, context }: Route.LoaderArgs ) => {
  const session = await getSession( request.headers.get( 'Cookie' ) );
  const authWrapper = session.get( 'authWrapper' );
  const requestRegion = await useRegionServer( request, true );
  const region = await useRegionServer( request );
  const language = useLanguageServer( request );
  const timeZone = getRequestTimeZone( request );

  setUser( {
    email: authWrapper?.userAccount?.email ?? 'Anonymous',
    region: requestRegion,
    userRegion: region,
    language,
    timeZone
  } );

  const loaderParams = zx.parseParams( params, loaderParamsSchema );

  const isBot = isbot( request.headers.get( 'user-agent' ) );
  const supportedLanguages = ['en', 'nl', 'fr', 'de'];
  const sessionLanguage = session.get( 'language' );

  const url = new URL( request.url );
  const path = getUrlPathWithoutLanguage( url.pathname, url.search );

  try {
    if ( !isBot ) {
      if ( loaderParams.lang == null && sessionLanguage !== 'en' ) {

        if ( sessionLanguage && sessionLanguage !== 'en' ) {
          return redirectDocument( `/${sessionLanguage}${path}` )
        }

        const acceptLanguage = acceptLanguageParser.parse( request.headers.get( 'accept-language' ) ?? '' )?.[0]?.code;
        if ( supportedLanguages.includes( acceptLanguage ) ) {
          session.set( 'language', acceptLanguage );

          if ( acceptLanguage !== 'en' ) {
            return redirectDocument( `/${acceptLanguage}${path}`, {
              headers: {
                'Set-Cookie': await commitSession( session )
              }
            } )
          }
        }
      } else if ( supportedLanguages.includes( loaderParams.lang ?? '' ) ) {
        session.set( 'language', loaderParams.lang );
      }

      if ( sessionLanguage && sessionLanguage !== 'en' && sessionLanguage !== loaderParams.lang ) {
        return redirectDocument( `/${sessionLanguage}${path}` )
      }
    
      if ( sessionLanguage && sessionLanguage === 'en' && loaderParams.lang != null ) {
        return redirectDocument( `${path}` );
      }
    }

    const livechat = await livechatCookie.parse( request.headers.get( 'Cookie' ) );

    const rewarbleApi = createRewarbleApi( request );
    const livechatResponse = livechat == null ? await rewarbleApi.web.account.livechat.get() : undefined;

    const headers = {};
    if( livechatResponse?.data ) {
      headers['Set-Cookie'] = await livechatCookie.serialize( {
        status: livechatResponse.data.status
      } )
    }

    const userLanguage = supportedLanguages.includes( loaderParams.lang ?? '' ) ? loaderParams.lang : sessionLanguage;
    return data( {
      commit: process.env.VERCEL_GIT_COMMIT_SHA ?? '1',
      baseUrl: `${url.protocol}//${url.host}`,
      path,
      userLanguage: userLanguage ?? FALLBACK_LANGUAGE,
      userRegion: region,
      userTimeZone: timeZone,
      translations: translations[ userLanguage ?? FALLBACK_LANGUAGE ],
      countries: countries[ userLanguage ?? FALLBACK_LANGUAGE ],
      nationalities: nationalities[ userLanguage ?? FALLBACK_LANGUAGE ],
      environment: process.env.NODE_ENV,
      gaTrackingId: process.env.GA_TRACKING_ID,
      recaptchaSiteKey: process.env.RECAPTCHA_V2_SITE_KEY,
      trustpilotData: {
        enabled: process.env.TRUSTPILOT_ENABLED === '1',
        score: 4.3,
        totalReviews: 366,
        starsImage: '4.5'
      },
      frontApp: {
        chatId: process.env.FRONTAPP_CHAT_ID ?? '',
        email: authWrapper?.userAccount.email,
        verificationSecret: authWrapper?.userAccount.email ? FrontApp.generateVerificationSecret( authWrapper.userAccount.email ) : undefined,
        nonce: new Date().getTime()
      },
      livechat: livechatResponse?.data ?? livechat ?? {
        status: 'inactive'
      }
    }, {
      headers
    } );
  } catch ( ex ) {
    if ( ex instanceof ApiError ) {
      throw ex.toResponse();
    }

    if ( ex instanceof Response ) {
      throw ex;
    }

    captureException( ex );
    throw internalServerError();
  }
}

export const shouldRevalidate : ShouldRevalidateFunction = ( { defaultShouldRevalidate, currentUrl, nextUrl } ) => {
  if ( currentUrl.pathname === nextUrl.pathname ) {
    return false;
  }

  return defaultShouldRevalidate;
}

export type RootLoaderData = typeof loader;

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'preconnect', href: 'https://cdn.rewarble.com' },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
];

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="/favicon-16x16.png"
          sizes="16x16" />
        <link
          rel="icon"
          href="/favicon-32x32.png"
          sizes="32x32" />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-96x96.png"
          sizes="96x96" />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/favicon.svg" />
        <link
          rel="shortcut icon"
          href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png" />
        <meta
          name="apple-mobile-web-app-title"
          content="Rewarble" />
        <link
          rel="manifest"
          href="/site.webmanifest" />
        <Meta />
        <Links />
      </head>
      <div style={ {
        '--page-color': '',
      } }>
        <Page>
          <div className={ 'container mx-auto px-4 flex flex-col gap-24 lg:gap-[100px] items-center' }>
            <div className={ 'md:pt-[80px] flex flex-col items-center gap-[50px]' }>
              <img
                src={ 'https://cdn.rewarble.com/error/icon.png' }
                className={ 'w-[112px] h-[104px]' }
                width={ 112 }
                height={ 104 } 
                alt={ 'Rewarble Error Icon' }
              />
              <div className={ 'flex flex-col items-center gap-2.5' }>
                <div className={ 'text-4xl font-semibold' }>{ isRouteErrorResponse( error ) ? error.status : 'Internal Server Error' }</div>
              </div>
              <div className={ 'flex flex-col gap-[30px] text-xl' }>
                <div className={ 'text-center text-pretty' }>
                  {'This page isn\'t loading like it should, and we\'re sorry about that. Our tech team is already looking into the problem and working to fix it.'}
                </div>
                <div className={ 'text-center text-pretty' }>
                  {'Please try to refresh or go to the home page to solve this issue.'}
                </div>
                <div className={ 'text-center text-pretty' }>
                  {'If the problem persists, please reach out to the online support:'} <strong className={ 'font-semibold' }>support@rewarble.com</strong>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </div>
    </html>
  )
}

const App = () => {
  const loaderData = useLoaderData<typeof loader>();
  const location = useLocation();
  const language = useLanguage();
  const [ queryClient ] = useState( new QueryClient() );

  useEffect( () => {
    if ( loaderData.gaTrackingId?.length ) {
      gtag.pageview( location.pathname, loaderData.gaTrackingId );
    }
  }, [location, loaderData?.gaTrackingId] );

  useEffect( () => {
    if ( typeof window?.['FrontChat'] !== 'undefined' ) {
      const options : {
        chatId: string;
        useDefaultLauncher: boolean;
        email?: string;
        userHash?: string;
        nonce?: string | number;
      } = {
        chatId: loaderData.frontApp.chatId,
        useDefaultLauncher: true,
        nonce: loaderData.frontApp.nonce
      };

      if ( loaderData.frontApp.email && loaderData.frontApp.verificationSecret ) {
        options.email = loaderData.frontApp.email;
        options.userHash = loaderData.frontApp.verificationSecret;
      }
      
      window?.['FrontChat']( 'init', options );
    }
  }, [] );

  return (
    <html lang={ language }>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="/favicon-16x16.png"
          sizes="16x16" />
        <link
          rel="icon"
          href="/favicon-32x32.png"
          sizes="32x32" />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-96x96.png"
          sizes="96x96" />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/favicon.svg" />
        <link
          rel="shortcut icon"
          href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png" />
        <meta
          name="apple-mobile-web-app-title"
          content="Rewarble" />
        <link
          rel="manifest"
          href="/site.webmanifest" />
        <link
          rel="stylesheet"
          type="text/css"
          href={ `https://cdn.rewarble.com/icons/v2/rewarble-icons.css?v=${loaderData.commit}` } 
        />
        <Meta />
        <Links />
      </head>
      <body>
        <GoogleReCaptchaProvider 
          type={ 'v2-checkbox' }
          siteKey={ loaderData.recaptchaSiteKey ?? '' }
        >
          <QueryClientProvider client={ queryClient }>
            <UserRequest />
            <>
              { loaderData?.environment === 'development' || !loaderData?.gaTrackingId ? null : (
                <>
                  <script
                    async
                    suppressHydrationWarning
                    src={ `https://www.googletagmanager.com/gtag/js?id=${loaderData.gaTrackingId}` }
                  />
                  <script
                    async
                    id="gtag-init"
                    suppressHydrationWarning
                    dangerouslySetInnerHTML={ {
                      __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${loaderData.gaTrackingId}', {
                      page_path: window.location.pathname,
                    });
                  `,
                    } }
                  />
                </>
              )}

              <ImageContextProvider providers={ [{
                name: 'images',
                baseUrl: 'https://images.rewarble.com',
                optimized: true
              }, {
                name: 'cdn',
                baseUrl: 'https://cdn.rewarble.com'
              }] }>
                <NavigationBlocker />
                <HamburgerMenu />
                <Header />
                <Page>
                  <Outlet />
                </Page>
                <div className={ 'container mx-auto px-4 pt-20' }>
                  <Footer />
                </div>
                { loaderData?.livechat?.status === 'active' &&
                  <script 
                    suppressHydrationWarning  
                    src='https://chat-assets.frontapp.com/v1/chat.bundle.js'
                    nonce={ loaderData.frontApp.nonce.toString() }
                  >
                  </script>
                }
              </ImageContextProvider>
              <script
                suppressHydrationWarning
                dangerouslySetInnerHTML={ {
                  __html: `window.env = ${JSON.stringify( {
                    NODE_ENV: loaderData?.environment
                  } )}`,
                } }
              />
            </>
          </QueryClientProvider>
        </GoogleReCaptchaProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default App;
